<template>
  <fb-page>
    <!-- 头部插槽 -->
    <fb-header slot="header" :title="title" fixed>
      <fb-button
        slot="left"
        icon="back"
        @click="backList(0)"
        size="small"
        type="primary"
      >
        返回
      </fb-button>
    </fb-header>
    <!-- 案件列表 -->
    <van-popup v-model="caseListVisible" position="top" get-container="body">
      <case-list :caseId="caseId" @select="handleCaseListSelect" />
    </van-popup>
    <!-- 日期组件 -->
    <van-calendar
      v-model="stageVisible"
      @confirm="selectStage"
      :default-date="income.applyTime"
    />
    <!-- 类型 -->
    <van-popup v-model="feeTypeListVisible" position="top" get-container="body">
      <stamp-type
        :options="feeTypeList"
        :radio="income.feeType"
        title="类型"
        @select="handlefeeTypeListSelect"
      />
    </van-popup>
    <van-popup v-model="payTypeListVisible" position="top" get-container="body">
      <stamp-type
        :options="payTypeList"
        :radio="income.payType"
        title="类型"
        @select="handlepayTypeListSelect"
      />
    </van-popup>
    <div class="oa-addIncome">
      <div class="oa-addIncome-title" @click="switchCaseList">
        <div v-if="caseName === ''" style="color:#999">请选择案件</div>
        <div v-else>{{ caseName }}</div>
      </div>
      <div class="oa-addIncome-type" @click="switchFeeTypeList">
        <div>类型</div>
        <div>
          <span>{{ income.feeType }}</span
          ><van-icon name="arrow-down" color="#CCCCCC" size="14px" />
        </div>
      </div>
      <div class="invoice-info">
        <div class="invoice-info-title">
          <span class="invoice-info-title-red">*</span>金额
        </div>
        <div>
          <van-field
            v-model="income.money"
            placeholder="请输入金额"
            readonly
            clickable
            input-align="right"
            @touchstart.stop="show = true"
          />
          <van-number-keyboard
            :show="show"
            theme="custom"
            extra-key="."
            close-button-text="完成"
            @blur="show = false"
            @input="onInput"
            @delete="onDelete"
          />
        </div>
      </div>
      <div class="oa-addIncome-type">
        <div style="color:#0079FE;margin-right:20px" @click="switchStage">
          {{ timestampToTime(income.applyTime.getTime()) }}
        </div>
        <van-field
          v-model="income.payee"
          placeholder="请输入领款人"
          input-align="right"
        />
      </div>
      <div class="oa-addIncome-type">
        <div @click="switchPayTypeList">
          <span>{{ income.payType }}</span
          ><van-icon name="arrow-down" color="#CCCCCC" size="14px" />
        </div>
        <van-field
          v-model="income.account"
          placeholder="请输入账号"
          input-align="right"
        />
      </div>

      <div class="oa-addIncome-type">
        <div class="switch-box">
          <div>是否已开票</div>
          <van-switch
            v-model="income.isArchive"
            active-color="#409EFF"
            inactive-color="#DCDFE6"
            size="16px"
          />
        </div>
        <div class="switch-box">
          <div>文书已提交</div>
          <van-switch
            v-model="income.isPaperArchive"
            active-color="#409EFF"
            inactive-color="#DCDFE6"
            size="16px"
          />
        </div>
      </div>

      <div class="loan-center-textarea">
        <van-field
          v-model="income.description"
          rows="3"
          autosize
          label="备注"
          type="textarea"
          placeholder="请输入备注"
        />
        <i class="iconfont icon-link" @click="addImg"></i>
      </div>

      <div class="oa-receive-content-box" v-if="income.files.length > 0">
        <div class="oa-receive-content-box-resource">
          <div
            class="resource-image-box"
            v-for="(items, index) in income.files"
            :key="index"
          >
            <i
              v-if="items.type === 'WORD'"
              class="iconfont icon-word"
              style="color: rgb(108, 168, 255);"
            ></i>
            <i
              v-if="items.type === 'EXCEL'"
              class="iconfont icon-exl"
              style="color: rgb(139, 221, 108);"
            ></i>
            <i
              v-if="items.type === 'PDF'"
              class="iconfont icon-PDF"
              style="color: rgb(62, 179, 240);"
            ></i>
            <i
              v-if="items.type === 'VIDEO'"
              class="iconfont icon-shipin"
              style="color: rgb(75, 158, 251);"
            ></i>
            <i
              v-if="items.type === 'AUDIO'"
              class="iconfont icon-shipin"
              style="color: rgb(75, 158, 251);"
            ></i>
            <img
              v-if="items.type === 'IMAGE'"
              :src="items.content"
              alt=""
              class="resource-image-img"
            />
            <img
              src="../../../../../public/img/icons/icon_delete.png"
              alt=""
              class="resource-image-delete"
              @click="deleteImg(index)"
            />
          </div>
        </div>
      </div>

      <div
        class="oa-approval"
        v-if="
          income.progress === 'UNAUDITED' ||
            income.progress === 'APPROVED' ||
            income.progress === 'CERTIFIED_FAILED'
        "
      >
        <div class="oa-approval-title" style="margin-bottom:20px">审批记录</div>
        <approval-record :typeId="this.id" :type="'DRAW_MONEY_RECORD'" />
      </div>
      <approval-user
        v-else-if="approvalUserComp"
        :Approval="this.Approval"
        :CC="this.CC"
        :isAdd="isAdd"
        :approvalUserSource="approvalUser"
        :ccUserSource="ccUser"
        @userSelect="userSelect"
        @coopSelect="coopSelect"
      />
      <operate-btn
        v-if="operateBtnShow"
        :isAdd="isAdd"
        :progress="income.progress"
        :approveRecordUsers="income.users"
        :Approval="this.Approval"
        :CC="this.CC"
        @save="save"
      />
    </div>
  </fb-page>
</template>

<script>
import goBackMixin from '@mixins/goBack'
import CaseList from '../../components/caseList'
import StampType from '../../components/stampType'
import OperateBtn from '../../components/operateBtn'
import ApprovalRecord from '../../components/approvalRecord'
import ApprovalUser from '../../components/approvalUser'
import { mapState, mapActions, mapMutations } from 'vuex'

import { qiniuBase } from '~api-config'
import { gettype } from '../../common/fileType'
export default {
  name: 'oa-addIncome',
  mixins: [goBackMixin],
  components: {
    CaseList,
    StampType,
    ApprovalRecord,
    ApprovalUser,
    OperateBtn
  },
  data() {
    return {
      id: 0,
      show: false,
      stageVisible: false,
      feeTypeListVisible: false, // 类型弹框
      feeTypeList: [
        {
          value: '办案费',
          text: '办案费'
        }
      ], // 类型列表
      payTypeListVisible: false, // 类型弹框
      payTypeList: [
        {
          value: '现金',
          text: '现金'
        }
      ], // 类型列表
      income: {
        money: '',
        applyTime: new Date(),
        description: '',
        isArchive: false,
        isPaperArchive: false,
        payee: '',
        account: '',
        feeType: '办案费',
        payType: '现金',
        files: []
      },
      isAdd: 0,
      title: '领款',
      caseListVisible: false, // 案件列表弹框
      caseName: '', // 案件名称
      caseId: 0, // 案件id
      CC: [], // 抄送人列表
      Approval: [], // 审批人列表
      ccUser: {}, // 当前选中的抄送人
      approvalUser: {}, // 当前选中的审批人
      approvalUserComp: false, // 是否渲染审批人组件
      coopUser: null,
      caseTypeName: '其他非诉',
      operateBtnShow: false
    }
  },
  computed: {
    ...mapState('user', ['organizationId', 'userInfo'])
  },
  methods: {
    ...mapActions('user', ['getOrganizationId']),
    ...mapMutations('oa', ['setOaTab']),
    // 返回列表页面
    backList(time = 1000) {
      if (this.$router.length <= 1) {
        console.log('直接返回到原生端')
        this.backToNative()
      } else {
        this.setOaTab('5')
        setTimeout(() => {
          this.$router.go(-1) // 返回上一层
        }, time)
      }
    },
    onDelete(value) {
      var money = this.income.money.toString()
      this.income.money = money.substring(0, money.length - 1)
    },
    onInput(value) {
      this.income.money = this.income.money + '' + value
    },
    // 项目名称点击事件
    switchCaseList() {
      if (this.isAdd === 0) {
        this.caseListVisible = !this.caseListVisible
      }
    },
    handleCaseListSelect(options) {
      this.caseName = options.label
      this.caseId = options.caseId
      this.caseTypeName = options.caseTypeName
      this.getUsers()
      this.switchCaseList()
    },
    // 时间戳转换成时间
    timestampToTime(cjsj) {
      var date = new Date(parseInt(cjsj)) // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + '-'
      var M =
        (date.getMonth() + 1 < 10
          ? '0' + (date.getMonth() + 1)
          : date.getMonth() + 1) + '-'
      var D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
      var h =
        ' ' +
        (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) +
        ':'
      var m =
        date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
      return Y + M + D + h + m
    },
    // 日期点击事件
    switchStage() {
      this.stageVisible = !this.stageVisible
    },
    selectStage(date) {
      console.log(date)
      this.income.applyTime = date
      this.switchStage()
    },
    // 类型点击事件
    switchFeeTypeList() {
      this.feeTypeListVisible = !this.feeTypeListVisible
    },
    handlefeeTypeListSelect(options) {
      // this.income.feeType = options.text
      this.income.feeType = options.value
      this.switchFeeTypeList()
    },
    switchPayTypeList() {
      this.payTypeListVisible = !this.payTypeListVisible
    },
    handlepayTypeListSelect(options) {
      // this.income.payType = options.text
      this.income.payType = options.value
      this.switchPayTypeList()
    },
    // 点击添加图片
    addImg(item) {
      const ua = navigator.userAgent
      if (ua === this.$ua.a) {
        // eslint-disable-next-line no-undef
        AND2JS.toAndrPickFile(1)
        return false
      } else {
        if (document.getElementById('file')) {
          document.getElementById('file').remove()
        }
        const ipt = document.createElement('input')
        ipt.id = 'file' // 设置ID以便删除
        ipt.type = 'file'
        // ipt.multiple = true // 开启支持多选
        ipt.style.display = 'none'
        document.body.appendChild(ipt)
        document.getElementById('file').onchange = () => {
          const f = ipt.files[0]
          const qiniu = new this.$qiniu()
          qiniu.UploadFile(
            'DEFAULT',
            f,
            {
              next: file => {},
              complete: (res, file) => {},
              error: () => {
                this.$toast('上传失败，请重新选择')
              }
            },
            (fileopt, key, file) => {
              console.log(fileopt)
              const url = qiniuBase + '/' + key
              if (!gettype(fileopt.name)) {
                return this.$notify('文件格式不支持')
              }
              var obj = {
                name: fileopt.name,
                type: gettype(fileopt.name),
                content: url,
                size: fileopt.size
              }
              this.income.files.push(obj)
              document.getElementById('file').remove()
            }
          )
        }
        ipt.click()
      }
    },
    // 接收原生端上传回调
    uploadFilesResultCallBack(data) {
      console.log(data)
      const list = JSON.parse(data)
      list.map(item => {
        if (!gettype(item.name)) {
          return this.$notify('文件格式不支持')
        }
        var obj = {
          name: item.name,
          type: gettype(item.name),
          content: item.url,
          size: item.size
        }
        this.collections.push(obj)
      })
    },
    deleteImg(index) {
      this.income.files.splice(index, 1)
    },
    // 获取审批人和抄送人
    async getUsers() {
      this.approvalUserComp = false
      var params = {
        organizationId: this.organizationId,
        caseTypeName: '默认',
        type: 'DRAW_MONEY_RECORD'
      }
      const res = await this.$axios.post(
        `${this.$base}/management/org/advice/user`,
        params
      )
      this.approvalUserComp = true
      const { code, data } = res.data
      if (code === 200 && data.length > 0) {
        const arr = [] // 审批
        const arrlist = [] // 抄送
        data.forEach(item => {
          if (item.type === 'APPROVER') {
            arr.push({
              label: item.userName,
              userid: item.userId,
              type: 'APPROVER',
              avatarUrl: item.userName
            })
          } else if (item.type === 'RECIPIENT') {
            arrlist.push({
              label: item.userName,
              userid: item.userId,
              type: 'RECIPIENT',
              avatarUrl: item.userName
            })
          }
        })
        this.CC = arrlist
        this.Approval = arr
        if (this.isAdd === 0) {
          this.ccUser = arrlist[0]
          this.approvalUser = arr[0]
        }
      }
    },
    /**
     * 处理审批流程组件返回的审批人和抄送人
     * 返回userList数组
     * 第一个元素是审批人
     * 第二个元素是抄送人
     */
    userSelect(userList) {
      this.approvalUser = userList[0]
      this.ccUser = userList[1]
    },
    // 协作人
    coopSelect(coopUser) {
      this.coopUser = coopUser
    },

    // 获取组织id
    async getId() {
      await this.getOrganizationId()
      await this.getUsers()
    },

    // 按钮事件
    save(progress) {
      this.$toast.loading({
        message: '加载中...',
        forbidClick: true
      })
      if (progress === 'DELETE') {
        this.deleteReport()
      } else {
        if (this.caseName === '') {
          this.$toast('请选择案件')
          return
        }
        if (this.income.money === '') {
          this.$toast('请输入金额')
          return
        }
        // 新增
        if (this.isAdd === 0) {
          const userObj = []
          userObj[0] = {
            type: 'APPLICANT',
            userId: this.userInfo.id,
            userName: this.userInfo.nickName
          }
          userObj[1] = {
            type: 'APPROVER',
            userId: this.approvalUser.userid,
            userName: this.approvalUser.label
          }
          userObj[2] = {
            type: 'RECIPIENT',
            userId: this.ccUser.userid,
            userName: this.ccUser.label
          }
          if (this.coopUser) {
            userObj.push({
              type: 'APPROVER',
              userId: this.coopUser.id,
              userName: this.coopUser.nickName
            })
          }
          const params = {
            ...this.income,
            progress: progress,
            applyUserId: this.userInfo.id,
            applyUserName: this.userInfo.nickName,
            caseId: this.caseId,
            caseName: this.caseName,
            isArchive: this.income.isArchive ? 0 : 1,
            isPaperArchive: this.income.isPaperArchive ? 0 : 1,
            users: userObj,
            applyTime: this.timestampToTime(this.income.applyTime.getTime())
          }
          this.$axios
            .post(`${this.$base}/management/draw`, params)
            .then(res => {
              this.$toast.clear()
              if (res.data.code === 200) {
                this.$toast.success('提交成功')
                this.backList()
              } else {
                // this.$message.error(res.data.message)
              }
            })
            .catch(err => {
              this.$toast.clear()
              console.log(err)
            })
        } else {
          // 修改
          this.updateLoan(progress)
        }
      }
    },

    // 删除事件
    deleteReport() {
      this.$dialog
        .confirm({
          title: '提示',
          message: '删除不可恢复！确定删除吗？'
        })
        .then(async () => {
          // on confirm
          const response = await this.$axios.post(
            `${this.$base}/management/draw/delete`,
            {
              data: { id: this.id }
            }
          )
          const { code } = response.data
          if (code === 200) {
            this.$toast.success('删除成功')
            this.backList()
          }
        })
        .catch(() => {
          // on cancel
        })
    },

    // 更新事件
    updateLoan(progress) {
      const params = {
        id: this.id,
        progress: progress
      }
      let url = ''
      // if (
      //   progress === 'APPROVED' ||
      //   progress === 'CERTIFIED_FAILED' ||
      //   progress === 'UNAUDITED'
      // ) {
      //   url = `${this.$base}/management/borrow/record/${this.loan.id}/progress`
      // } else {
      //   url = `${this.$base}/management/borrow/record/${this.loan.id}`
      // }
      url = `${this.$base}/management/draw/progress`
      this.$axios
        .post(url, params)
        .then(res => {
          this.$toast.clear()
          if (res.data.code === 200) {
            this.$toast.success('提交成功')
            this.backList()
          } else {
            // this.$message.error(res.data.message)
          }
        })
        .catch(err => {
          this.$toast.clear()
          console.log(err)
        })
    }
  },
  async created() {
    // 关联组件内方法ocrCaseResultCallback 至 JSBridge/uploadFiles
    this.$hook(this.$bridge.uploadFiles, this.uploadFilesResultCallBack)
    // 组件销毁的时候释放桥接方法
    this.$once('hook:destroyed', () => {
      this.$unhook(this.$bridge.uploadFiles)
    })
    await this.getId()
    this.isAdd = parseInt(this.$route.query.isAdd)
    // isAdd 0表示新增  1表示修改
    if (this.isAdd === 1) {
      this.id = this.$route.query.commonId
      const res = await this.$axios.get(
        `${this.$base}/management/draw/${this.$route.query.commonId}`
      )
      const { code, data } = res.data
      if (code === 200) {
        let avatarUrl = ''
        let applicantName = ''
        data.users.map((item, i) => {
          if (item.type === 'APPLICANT') {
            avatarUrl = item.userName
            applicantName = item.userName
          }
        })
        data.avatarUrl = avatarUrl
        data.applicantName = applicantName
        const income = data
        income.applyTime = new Date(income.applyTime)
        income.isArchive = income.isArchive === 0
        income.isPaperArchive = income.isPaperArchive === 0
        this.income = income
        this.approveRecordUsers = income.users
        this.operateBtnShow = true
        this.caseId = income.caseId
        this.caseName = income.caseName
        this.approveRecordUsers.map(item => {
          if (item.type === 'APPROVER') {
            this.approvalUser = {
              label: item.userName,
              userid: item.userId,
              type: 'APPROVER',
              avatarUrl: item.userName
            }
          } else if (item.type === 'RECIPIENT') {
            this.ccUser = {
              label: item.userName,
              userid: item.userId,
              type: 'RECIPIENT',
              avatarUrl: item.userName
            }
          }
        })
      } else {
        this.$notify('找不到数据')
      }
    } else {
      this.switchCaseList()
      this.operateBtnShow = true
    }
  },
  mounted() {}
}
</script>

<style lang="stylus">
.approval-title
  text-align center
  height 40px
  line-height 40px
  font-size 16px
  color rgba(69, 90, 100, 0.6)
.oa-addIncome
  position relative
  padding 0 20px
  box-sizing border-box
  font-size 14px
  padding-bottom 100px
  & .oa-addIncome-type
    display flex
    align-items center
    justify-content space-between
    padding 15px 10px
    box-sizing border-box
    & .switch-box
      display flex
      flex-direction row
      align-items center
    & .van-cell
      flex 1
      padding 0
  & .loan-center-textarea
    display flex
    flex-direction row
    align-items flex-end
    & .iconfont
      margin-left 10px
      font-size 20px
  & .oa-receive-content-box
    display flex
    flex-direction row
    justify-content space-between
    align-items center
    margin-top 20px
    & .oa-receive-content-box-resource
      display flex
      flex-direction row
      align-items center
      flex-wrap wrap
      & .resource-image-box
        position relative
        width 70px
        height 70px
        margin-right 10px
        margin-bottom 10px
        & .iconfont
          font-size 70px
        & .resource-image-img
          width 100%
          height 100%
          object-fit contain
        & .resource-image-delete
          position absolute
          width 20px
          height 20px
          right -10px
          top -10px
  & .oa-addIncome-title
    width 100%
    padding 10px 10px
    box-sizing border-box
    border-bottom 1px solid rgba(242, 242, 242, 1)
    font-size 16px
    font-weight 400
  & .invoice-info
    display flex
    flex-direction row
    align-items center
    justify-content space-between
    padding 0 10px
    height 45px
    box-sizing border-box
    font-size 14px
    border-bottom 1px solid rgba(242, 242, 242, 1)
    div
      & .van-cell
        padding 0px !important
    & .invoice-info-title
      position relative
      & .invoice-info-title-red
        position absolute
        left -8px
        color red
        font-size 14px
  & .oa-approval
    border-top 10px solid rgba(242, 242, 242, 1)
    padding 20px
    box-sizing border-box
    position relative
    & .oa-approval-title
      font-size 14px
      color #CCCCCC
    & .oa-approval-step
      height 80px
      padding 0 20px
      display flex
      flex-direction row
      align-items center
      justify-content space-between
      border-bottom 1px solid rgba(242, 242, 242, 1)
      font-size 14px
      & .oa-approval-step-div
        display flex
        flex-direction row
        align-items center
        & .oa-approval-step-dot
          width 10px
          height 10px
          border-radius 5px
          background rgba(242, 242, 242, 1)
          margin-right 10px
      & .oa-approval-userInfo
        display flex
        flex-direction column
        align-items flex-end
        img
          width 30px
          height 30px
          border-radius 15px
    & .oa-approval-step-line
      width 2px
      height 80px
      background rgba(242, 242, 242, 1)
      position absolute
      bottom 60px
      left 44px
</style>
