var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "fb-page",
    [
      _c(
        "fb-header",
        {
          attrs: { slot: "header", title: _vm.title, fixed: "" },
          slot: "header",
        },
        [
          _c(
            "fb-button",
            {
              attrs: {
                slot: "left",
                icon: "back",
                size: "small",
                type: "primary",
              },
              on: {
                click: function ($event) {
                  return _vm.backList(0)
                },
              },
              slot: "left",
            },
            [_vm._v(" 返回 ")]
          ),
        ],
        1
      ),
      _c(
        "van-popup",
        {
          attrs: { position: "top", "get-container": "body" },
          model: {
            value: _vm.caseListVisible,
            callback: function ($$v) {
              _vm.caseListVisible = $$v
            },
            expression: "caseListVisible",
          },
        },
        [
          _c("case-list", {
            attrs: { caseId: _vm.caseId },
            on: { select: _vm.handleCaseListSelect },
          }),
        ],
        1
      ),
      _c("van-calendar", {
        attrs: { "default-date": _vm.income.applyTime },
        on: { confirm: _vm.selectStage },
        model: {
          value: _vm.stageVisible,
          callback: function ($$v) {
            _vm.stageVisible = $$v
          },
          expression: "stageVisible",
        },
      }),
      _c(
        "van-popup",
        {
          attrs: { position: "top", "get-container": "body" },
          model: {
            value: _vm.feeTypeListVisible,
            callback: function ($$v) {
              _vm.feeTypeListVisible = $$v
            },
            expression: "feeTypeListVisible",
          },
        },
        [
          _c("stamp-type", {
            attrs: {
              options: _vm.feeTypeList,
              radio: _vm.income.feeType,
              title: "类型",
            },
            on: { select: _vm.handlefeeTypeListSelect },
          }),
        ],
        1
      ),
      _c(
        "van-popup",
        {
          attrs: { position: "top", "get-container": "body" },
          model: {
            value: _vm.payTypeListVisible,
            callback: function ($$v) {
              _vm.payTypeListVisible = $$v
            },
            expression: "payTypeListVisible",
          },
        },
        [
          _c("stamp-type", {
            attrs: {
              options: _vm.payTypeList,
              radio: _vm.income.payType,
              title: "类型",
            },
            on: { select: _vm.handlepayTypeListSelect },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "oa-addIncome" },
        [
          _c(
            "div",
            {
              staticClass: "oa-addIncome-title",
              on: { click: _vm.switchCaseList },
            },
            [
              _vm.caseName === ""
                ? _c("div", { staticStyle: { color: "#999" } }, [
                    _vm._v("请选择案件"),
                  ])
                : _c("div", [_vm._v(_vm._s(_vm.caseName))]),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "oa-addIncome-type",
              on: { click: _vm.switchFeeTypeList },
            },
            [
              _c("div", [_vm._v("类型")]),
              _c(
                "div",
                [
                  _c("span", [_vm._v(_vm._s(_vm.income.feeType))]),
                  _c("van-icon", {
                    attrs: {
                      name: "arrow-down",
                      color: "#CCCCCC",
                      size: "14px",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c("div", { staticClass: "invoice-info" }, [
            _c("div", { staticClass: "invoice-info-title" }, [
              _c("span", { staticClass: "invoice-info-title-red" }, [
                _vm._v("*"),
              ]),
              _vm._v("金额 "),
            ]),
            _c(
              "div",
              [
                _c("van-field", {
                  attrs: {
                    placeholder: "请输入金额",
                    readonly: "",
                    clickable: "",
                    "input-align": "right",
                  },
                  on: {
                    touchstart: function ($event) {
                      $event.stopPropagation()
                      _vm.show = true
                    },
                  },
                  model: {
                    value: _vm.income.money,
                    callback: function ($$v) {
                      _vm.$set(_vm.income, "money", $$v)
                    },
                    expression: "income.money",
                  },
                }),
                _c("van-number-keyboard", {
                  attrs: {
                    show: _vm.show,
                    theme: "custom",
                    "extra-key": ".",
                    "close-button-text": "完成",
                  },
                  on: {
                    blur: function ($event) {
                      _vm.show = false
                    },
                    input: _vm.onInput,
                    delete: _vm.onDelete,
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "oa-addIncome-type" },
            [
              _c(
                "div",
                {
                  staticStyle: { color: "#0079FE", "margin-right": "20px" },
                  on: { click: _vm.switchStage },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.timestampToTime(_vm.income.applyTime.getTime())
                      ) +
                      " "
                  ),
                ]
              ),
              _c("van-field", {
                attrs: { placeholder: "请输入领款人", "input-align": "right" },
                model: {
                  value: _vm.income.payee,
                  callback: function ($$v) {
                    _vm.$set(_vm.income, "payee", $$v)
                  },
                  expression: "income.payee",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "oa-addIncome-type" },
            [
              _c(
                "div",
                { on: { click: _vm.switchPayTypeList } },
                [
                  _c("span", [_vm._v(_vm._s(_vm.income.payType))]),
                  _c("van-icon", {
                    attrs: {
                      name: "arrow-down",
                      color: "#CCCCCC",
                      size: "14px",
                    },
                  }),
                ],
                1
              ),
              _c("van-field", {
                attrs: { placeholder: "请输入账号", "input-align": "right" },
                model: {
                  value: _vm.income.account,
                  callback: function ($$v) {
                    _vm.$set(_vm.income, "account", $$v)
                  },
                  expression: "income.account",
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "oa-addIncome-type" }, [
            _c(
              "div",
              { staticClass: "switch-box" },
              [
                _c("div", [_vm._v("是否已开票")]),
                _c("van-switch", {
                  attrs: {
                    "active-color": "#409EFF",
                    "inactive-color": "#DCDFE6",
                    size: "16px",
                  },
                  model: {
                    value: _vm.income.isArchive,
                    callback: function ($$v) {
                      _vm.$set(_vm.income, "isArchive", $$v)
                    },
                    expression: "income.isArchive",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "switch-box" },
              [
                _c("div", [_vm._v("文书已提交")]),
                _c("van-switch", {
                  attrs: {
                    "active-color": "#409EFF",
                    "inactive-color": "#DCDFE6",
                    size: "16px",
                  },
                  model: {
                    value: _vm.income.isPaperArchive,
                    callback: function ($$v) {
                      _vm.$set(_vm.income, "isPaperArchive", $$v)
                    },
                    expression: "income.isPaperArchive",
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "loan-center-textarea" },
            [
              _c("van-field", {
                attrs: {
                  rows: "3",
                  autosize: "",
                  label: "备注",
                  type: "textarea",
                  placeholder: "请输入备注",
                },
                model: {
                  value: _vm.income.description,
                  callback: function ($$v) {
                    _vm.$set(_vm.income, "description", $$v)
                  },
                  expression: "income.description",
                },
              }),
              _c("i", {
                staticClass: "iconfont icon-link",
                on: { click: _vm.addImg },
              }),
            ],
            1
          ),
          _vm.income.files.length > 0
            ? _c("div", { staticClass: "oa-receive-content-box" }, [
                _c(
                  "div",
                  { staticClass: "oa-receive-content-box-resource" },
                  _vm._l(_vm.income.files, function (items, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "resource-image-box" },
                      [
                        items.type === "WORD"
                          ? _c("i", {
                              staticClass: "iconfont icon-word",
                              staticStyle: { color: "rgb(108, 168, 255)" },
                            })
                          : _vm._e(),
                        items.type === "EXCEL"
                          ? _c("i", {
                              staticClass: "iconfont icon-exl",
                              staticStyle: { color: "rgb(139, 221, 108)" },
                            })
                          : _vm._e(),
                        items.type === "PDF"
                          ? _c("i", {
                              staticClass: "iconfont icon-PDF",
                              staticStyle: { color: "rgb(62, 179, 240)" },
                            })
                          : _vm._e(),
                        items.type === "VIDEO"
                          ? _c("i", {
                              staticClass: "iconfont icon-shipin",
                              staticStyle: { color: "rgb(75, 158, 251)" },
                            })
                          : _vm._e(),
                        items.type === "AUDIO"
                          ? _c("i", {
                              staticClass: "iconfont icon-shipin",
                              staticStyle: { color: "rgb(75, 158, 251)" },
                            })
                          : _vm._e(),
                        items.type === "IMAGE"
                          ? _c("img", {
                              staticClass: "resource-image-img",
                              attrs: { src: items.content, alt: "" },
                            })
                          : _vm._e(),
                        _c("img", {
                          staticClass: "resource-image-delete",
                          attrs: {
                            src: require("../../../../../public/img/icons/icon_delete.png"),
                            alt: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.deleteImg(index)
                            },
                          },
                        }),
                      ]
                    )
                  }),
                  0
                ),
              ])
            : _vm._e(),
          _vm.income.progress === "UNAUDITED" ||
          _vm.income.progress === "APPROVED" ||
          _vm.income.progress === "CERTIFIED_FAILED"
            ? _c(
                "div",
                { staticClass: "oa-approval" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "oa-approval-title",
                      staticStyle: { "margin-bottom": "20px" },
                    },
                    [_vm._v("审批记录")]
                  ),
                  _c("approval-record", {
                    attrs: { typeId: this.id, type: "DRAW_MONEY_RECORD" },
                  }),
                ],
                1
              )
            : _vm.approvalUserComp
            ? _c("approval-user", {
                attrs: {
                  Approval: this.Approval,
                  CC: this.CC,
                  isAdd: _vm.isAdd,
                  approvalUserSource: _vm.approvalUser,
                  ccUserSource: _vm.ccUser,
                },
                on: { userSelect: _vm.userSelect, coopSelect: _vm.coopSelect },
              })
            : _vm._e(),
          _vm.operateBtnShow
            ? _c("operate-btn", {
                attrs: {
                  isAdd: _vm.isAdd,
                  progress: _vm.income.progress,
                  approveRecordUsers: _vm.income.users,
                  Approval: this.Approval,
                  CC: this.CC,
                },
                on: { save: _vm.save },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }